var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "n-page",
    [
      _c(
        "a-row",
        { attrs: { type: "flex" } },
        [
          _c(
            "a-col",
            { attrs: { xs: 24, sm: 24, md: 14, lg: 16 } },
            [
              _c(
                "a-row",
                { attrs: { type: "flex", gutter: 10 } },
                [
                  _c(
                    "a-col",
                    { attrs: { xs: 24, sm: 24, md: 8, lg: 6 } },
                    [
                      _c("a-input", {
                        staticStyle: { width: "100%", "margin-bottom": "10px" },
                        attrs: { placeholder: "Keyword Search" },
                        model: {
                          value: _vm.filters.keyword,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "keyword", $$v)
                          },
                          expression: "filters.keyword",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { xs: 24, sm: 24, md: 8, lg: 6 } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: {
                            "min-width": "100%",
                            "margin-bottom": "10px",
                          },
                          model: {
                            value: _vm.filters.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "type", $$v)
                            },
                            expression: "filters.type",
                          },
                        },
                        [
                          _c("a-select-option", { attrs: { value: "all" } }, [
                            _vm._v("All Members"),
                          ]),
                          _c(
                            "a-select-option",
                            { attrs: { value: "accepted" } },
                            [_vm._v("Active Members")]
                          ),
                          _c(
                            "a-select-option",
                            { attrs: { value: "requested" } },
                            [_vm._v("Requested To Join")]
                          ),
                          _c(
                            "a-select-option",
                            { attrs: { value: "invited" } },
                            [_vm._v("Pending Invitation Acceptance")]
                          ),
                          _c(
                            "a-select-option",
                            { attrs: { value: "archived" } },
                            [_vm._v("Archived Members")]
                          ),
                          _c(
                            "a-select-option",
                            { attrs: { value: "declined" } },
                            [_vm._v("Declined Invitations")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            {
              staticClass: "gx-text-right",
              attrs: { xs: 24, sm: 24, md: 10, lg: 8 },
            },
            [
              _c(
                "a-button",
                {
                  staticStyle: {
                    "margin-bottom": "12px",
                    height: "35px",
                    "background-color": "rgb(39, 174, 96) !important",
                    "border-color": "rgb(39, 174, 96) !important",
                  },
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.addMemberModal },
                },
                [_vm._v("Add Member")]
              ),
              _c(
                "a-button",
                {
                  staticStyle: { "margin-bottom": "12px", height: "35px" },
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.openMemberModal },
                },
                [_vm._v("Search & Invite Members")]
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-card",
                {
                  staticClass: "gx-card-table-full gx-table-responsive",
                  attrs: { title: "Members" },
                },
                [
                  _c("club-member-table", {
                    attrs: { filters: _vm.filters, "club-id": 1 },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("invite-member-modal", {
        attrs: { visible: _vm.inviteMemberVisible },
        on: { close: _vm.closeMemberModal },
      }),
      _c("add-member-modal", {
        attrs: { visible: _vm.addMemberVisible },
        on: { close: _vm.closeMemberModal },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }